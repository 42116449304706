import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { Absolute } from './primitives/absolute';
import { FlexBox } from './primitives/flex';

const Rectangle = styled(Box)({
  height: '90px',
  width: '380px',
  clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 17% 99%)',
  backgroundColor: '#00a6dd',
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: -1
});

const AmbassdorOverlay = () => {
  return (
    <Absolute right={0} top="50px">
      <FlexBox position="relative" height="160px" padding="0 20px 0 32px" maxWidth="380px">
        <Rectangle />
        <Box
          component="img"
          src="https://web.archive.org/web/20211130082030im_/https://listingloop.com.au/wp-content/uploads/2020/06/andrew_bogut_photo.png"
          alt="Andrew Bogut"
          height="100%"
        />
        <Typography
          fontSize="13px"
          fontWeight={600}
          lineHeight="16px"
          margin="0 0 16px 16px"
          maxWidth="180px"
        >
          NBA & NBL Basketball Legend & Listing Loop Ambassador,
          <Box color="white">Andrew Bogut</Box>
        </Typography>
      </FlexBox>
    </Absolute>
  );
};

export default AmbassdorOverlay;
